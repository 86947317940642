import { createAction, props } from '@ngrx/store';
import { Annotation, Recording } from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';

export const getAnnotationsRequestAction = createAction(
  'GetAnnotationsRequest',
  props<{ id: string }>(),
);
export const getAnnotationsSuccessAction = createAction(
  'GetAnnotationsSuccess',
  props<{ annotations: Annotation[] }>(),
);
export const getAnnotationsFailureAction = createAction(
  'GetAnnotationsFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const pollAnnotationsAction = createAction(
  'PollAnnotations',
  props<{ id: string; nextPingPong: number }>(),
);

export const pollAnnotationsRequestAction = createAction(
  'PollAnnotationsRequest',
  props<{ id: string; nextPingPong: number; status?: string }>(),
);
export const pollAnnotationsSuccessAction = createAction(
  'PollAnnotationsSuccess',
  props<{ annotations: Annotation[]; nextPingPong: number; id: string }>(),
);
export const pollAnnotationsFailureAction = createAction(
  'PollAnnotationsFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const prepareAnnotationPostRequestAction = createAction(
  'PrepareAnnotationPostRequestAction',
  props<{
    annotation: Partial<Annotation>;
    currentTime: number;
    inOverlay: boolean;
  }>(),
);

export const prepareLiveMatchAnnotationPostRequestAction = createAction(
  'PrepareLiveMatchAnnotationPostRequestAction',
  props<{
    annotation: Partial<Annotation>;
    currentTime: number;
    videoPlayer: boolean;
    inOverlay: boolean;
  }>(),
);

export const postAnnotationRequestAction = createAction(
  'PostAnnotationRequest',
  props<{
    annotation: Partial<Annotation>;
    live: boolean;
    inOverlay: boolean;
  }>(),
);
export const postAnnotationSuccessAction = createAction(
  'PostAnnotationSuccess',
  props<{ annotation: Annotation }>(),
);
export const postAnnotationFailureAction = createAction(
  'PostAnnotationFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const editAnnotationNameRequestAction = createAction(
  'EditAnnotationNameRequest',
  props<{ id: string; name: string; description: string }>(),
);
export const editAnnotationNameSuccessAction = createAction(
  'EditAnnotationNameSuccess',
  props<{ annotation: Annotation }>(),
);
export const editAnnotationNameFailureAction = createAction(
  'EditAnnotationNameFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const prepareCurrentAnnotationTrimmingRequestAction = createAction(
  'PrepareAnnotationTrimmingRequest',
  props<{ newValues: { from: number; to: number } }>(),
);
export const editAnnotationTrimmingRequestAction = createAction(
  'EditAnnotationTrimmingRequest',
  props<{ id: string; from?: number; to?: number; timestamp?: number }>(),
);
export const editAnnotationTrimmingSuccessAction = createAction(
  'EditAnnotationTrimmingSuccess',
  props<{ annotation: Annotation }>(),
);
export const editAnnotationTrimmingFailureAction = createAction(
  'EditAnnotationTrimmingFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const editAnnotationTrimmingNoRightsAction = createAction(
  'editAnnotationTrimmingNoRightsAction',
  props<{ id: string }>(),
);

export const deleteAnnotationsRequestAction = createAction(
  'DeleteAnnotationRequest',
  props<{ annotations: Annotation[] }>(),
);
export const deleteAnnotationsSuccessAction = createAction(
  'DeleteAnnotationSuccess',
  props<{ ids: string[] }>(),
);
export const deleteAnnotationsFailureAction = createAction(
  'DeleteAnnotationFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const getAnnotationsRecordingRequestAction = createAction(
  'GetAnnotationsRecordingRequest',
  props<{ id: string }>(),
);
export const getAnnotationsRecordingSuccessAction = createAction(
  'GetAnnotationsRecordingSuccess',
  props<{ annotations: Annotation[]; recording: Recording }>(),
);
export const getAnnotationsRecordingFailureAction = createAction(
  'GetAnnotationsRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const downloadAnnotationRequestAction = createAction(
  'DownloadAnnotationRequest',
  props<{ id: string }>(),
);
export const downloadAnnotationSuccessAction = createAction(
  'DownloadAnnotationSuccess',
  props<{ annotation: Annotation }>(),
);
export const downloadAnnotationFailureAction = createAction(
  'DownloadAnnotationFailure',
);
export const refreshAnnotationThumbnailAction = createAction(
  'RefreshAnnotationThumbnail',
  props<{ annotationId: string; url: string }>(),
);

export const showChangeAnnotationNotificationAction = createAction(
  'ShowChangeAnnotationNotificationAction',
  props<{ userId: string; status: string }>(),
);
