<div
  class="video-widget-container"
  (click)="onSelectTag()"
  *ngIf="widget"
  [ngClass]="{
    selected: isSelected,
    higher: showRecordingName && teamName.length > 0,
  }"
>
  @if (isMultisectionEnabled$ | async) {
    <div class="checkbox--wrapper" (click)="$event.stopPropagation()">
      <cmv-checkbox
        (checkedChange)="onSelectChange($event)"
        [checked]="isAnnotationSelected$ | async"
      ></cmv-checkbox>
    </div>
  }

  @if (widget.selected) {
    <div class="active-indicator"></div>
  }

  <div class="thumbnail-wrapper" [class.loaded]="isThumbnailLoaded">
    <div class="thumbnail-preview"></div>
    <img
      class="video-thumbnail"
      (load)="onThumbnailLoad()"
      (error)="waitAndReload()"
      [src]="widget.thumbnail"
      #thumbnail
    />
    <div class="cmv-h7 video-time">{{ duration }}</div>
    <div
      *ngIf="widget.shared"
      class="shared-annotation-icon"
      matTooltipClass="shareTooltip"
      [matTooltip]="
        'components.modals.editModal.sharedBy'
          | translate: { userEmail: widget.userEmail }
      "
    >
      <mat-icon class="cmv-white-icon" svgIcon="cmv-share"></mat-icon>
    </div>
  </div>
  <div class="video-description">
    @if (showRecordingName) {
      <div
        class="cmv-h5 video-title"
        [title]="matchName || widget?.recordingName"
      >
        {{ matchName || widget?.recordingName }}
      </div>
    }

    <div [class]="widgetNameClasses" [title]="widget.name">
      {{ widget.name }}
    </div>

    @if (teamName.length > 0) {
      <div class="video-detail cmv-paragraph">{{ teamName }}</div>
    }

    <div class="video-date cmv-paragraph">
      {{ matchDate }}
      <span class="annotatio-time">{{ annotationTime }}</span>
    </div>
  </div>

  <button
    class="cmv-more-button"
    mat-icon-button
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="detailMenu"
  >
    <img src="assets/icons/more_icon_ver.svg" />
  </button>

  <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
    <div (keydown)="$event.stopPropagation()">
      <button
        class="cmv-menu-item"
        (click)="openModal('EditModal')"
        mat-menu-item
      >
        <span class="cmv-h4">{{ 'basic.editTag' | translate }}</span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-edit"
          ></mat-icon>
        </span>
      </button>
      <button
        class="cmv-menu-item"
        (click)="showTrimming()"
        [disabled]="trimmingDisabled"
        mat-menu-item
      >
        <span class="cmv-h4">{{ 'basic.trimTag' | translate }}</span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon"
            [ngClass]="{
              'cmv-silver-icon': trimmingDisabled$ | async,
              'cmv-gray-icon': trimmingEnabled$ | async,
            }"
            svgIcon="cmv-trim"
          ></mat-icon>
        </span>
      </button>
      <button
        class="cmv-menu-item"
        (click)="openModal('AddToPlaylistModal')"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'basic.addToPlaylist' | translate }}
        </span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-add-playlist"
          ></mat-icon>
        </span>
      </button>
      <button
        class="cmv-menu-item"
        *ngIf="isSharingEnabled$ | async"
        [disabled]="widget.shared"
        (click)="openModal('ShareModal')"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'basic.shareAnnotation' | translate }}
        </span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-white-icon"
            svgIcon="cmv-share"
          ></mat-icon>
        </span>
      </button>
      <button
        *ngIf="!this.hideDownload && (currentRecordingHasVideo$ | async)"
        class="cmv-menu-item"
        mat-menu-item
        (click)="onDownloadAnnotation()"
      >
        <span class="cmv-h4">
          {{ downloadButtonLabel | translate }}
        </span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-white-icon"
            [svgIcon]="downloadButtonIcon"
          ></mat-icon>
        </span>
      </button>
      <button
        class="cmv-menu-item"
        [disabled]="widget.shared"
        (click)="openModal('RemoveModal')"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'basic.removeTag' | translate }}
        </span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-delete"
          ></mat-icon>
        </span>
      </button>
    </div>
  </mat-menu>
</div>
@if (widget?.status === renderStatus.RENDERING) {
  <cmv-loading-bar></cmv-loading-bar>
}
