import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { getRecordingTeamName } from 'src/app/app.utils';
import {
  Annotation,
  AnnotationTeam,
  RenderStatus,
} from '../../../../api/models';
import { AppState } from '../../models/app.state';
import { EditModalModel } from '../../models/edit-modal.model';
import {
  ShareableEntityModel,
  ShareableEntityType,
} from '../../models/sharing.model';
import { getSharingTeamsRequestAction } from '../../store/actions/sharing.actions';
import { $canNavigate } from '../../store/selectors/recording.selectors';
import {
  $isLargeDevice,
  $isSmallDevice,
} from '../../store/selectors/responsivity.selectors';
import { $isSharingEnabled } from '../../store/selectors/sharing.selectors';
import { AddToPlaylistModalComponent } from '../modal/add-to-playlist-modal/add-to-playlist-modal.component';
import {
  EditModalComponent,
  EditModalTypes,
} from '../modal/edit-modal/edit-modal.component';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { ShareModalComponent } from '../modal/share-modal/share-modal.component';
import { downloadAnnotationRequestAction } from '../../store/actions/annotation.actions';
import {
  $currentRecordingHasVideo,
  $isAnnotationSelectedById,
  $isMultiselectEnabled,
} from '../../store/selectors/current-selections.selectors';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { LoadingBarComponent } from '../loading-bar/loading-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import {
  selectAnnotationAction,
  unselectAnnotationAction,
} from '../../store/actions/current-selections.actions';

const SECONDS = 60;
const DELAY_TO_LOAD_THUMBNAIL = 500;
const MAX_DATA_RETRY = 15;

@Component({
  selector: 'cmv-video-widget',
  templateUrl: './video-widget.component.html',
  styleUrls: ['./video-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    MatTooltip,
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    LoadingBarComponent,
    AsyncPipe,
    TranslateModule,
    CheckboxComponent,
  ],
})
export class VideoWidgetComponent implements OnDestroy {
  @ViewChild('thumbnail') thumbnail: ElementRef;

  @Input() trimmingDisabled = false;
  @Input() fromPlaylistId: string | undefined;
  @Input() showRecordingName = false;
  @Input() hideDownload = false;

  @Output() deleteTag = new EventEmitter<Annotation>();
  @Output() selectedWidgetIdChange = new EventEmitter<{
    id: string;
    recordingId: string;
    annotation: Annotation;
  }>();
  @Output() trimVideo = new EventEmitter<{ id: string; recordingId: string }>();
  @Output() editAnnotation = new EventEmitter<Annotation>();
  @Output() shareAnnotation = new EventEmitter<Annotation>();

  widget: Annotation;
  dataRetry = 0;
  isThumbnailLoaded = false;
  renderStatus = RenderStatus;
  isAnnotationSelected$: Observable<boolean>;

  readonly trimmingEnabled$ = this.store.pipe(select($isLargeDevice));
  readonly trimmingDisabled$ = this.store.pipe(select($isSmallDevice));
  readonly isSharingEnabled$ = this.store.pipe(select($isSharingEnabled));
  readonly currentRecordingHasVideo$ = this.store.pipe(
    select($currentRecordingHasVideo),
  );
  readonly isMultisectionEnabled$ = this.store.pipe(
    select($isMultiselectEnabled),
  );

  private readonly unsubscribe$ = new Subject<void>();

  @Input()
  set widgetData(data: Annotation) {
    if (data) {
      if (this.widget && this.widget.thumbnail !== data.thumbnail) {
        this.dataRetry = 0;
        this.isThumbnailLoaded = false;
      }

      this.widget = data;
      this.isAnnotationSelected$ = this.store.select(
        $isAnnotationSelectedById(data.id),
      );
    }
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelectChange(value: boolean): void {
    const annotationId = this.widget.id;

    this.store.dispatch(
      value
        ? selectAnnotationAction({ annotationId })
        : unselectAnnotationAction({ annotationId }),
    );
  }

  onSelectTag(): void {
    this.store
      .pipe(take(1), select($canNavigate(this.widget.recordingId)))
      .subscribe(canNavigate => {
        if (canNavigate) {
          this.selectedWidgetIdChange.emit({
            id: this.widget.id,
            recordingId: this.widget.recordingId,
            annotation: this.widget,
          });
        }
      });
  }

  onDownloadAnnotation(): void {
    if (this.widget.status === RenderStatus.DONE && this.widget.url) {
      window.open(this.widget.url, '_blank');
    } else if (this.widget.status !== RenderStatus.RENDERING) {
      this.store.dispatch(
        downloadAnnotationRequestAction({ id: this.widget.id }),
      );
    }
  }

  showTrimming(): void {
    this.trimVideo.emit({
      id: this.widget.id,
      recordingId: this.widget.recordingId,
    });
  }

  onThumbnailLoad(): void {
    this.isThumbnailLoaded = true;
  }

  waitAndReload(): void {
    if (this.dataRetry < MAX_DATA_RETRY) {
      if (this.widget.thumbnail != null && this.thumbnail != null) {
        this.dataRetry++;
        setTimeout(() => {
          if (!this.widget.thumbnail) {
            return;
          }

          const thumbnail = new URL(this.widget.thumbnail);
          thumbnail.searchParams.set('retry', `${this.dataRetry}`);
          this.thumbnail.nativeElement.src = `${thumbnail}`;
        }, DELAY_TO_LOAD_THUMBNAIL * this.dataRetry);
      }
    }
  }

  openModal(type: string): void {
    let dialog;
    let handleFunction;
    switch (type) {
      case 'EditModal':
        dialog = this.dialog.open(EditModalComponent, {
          autoFocus: false,
          data: {
            title: this.widget.name,
            description: this.widget.description,
            type: EditModalTypes.TAG_EDIT,
            sharedBy: this.widget.shared ? this.widget.userEmail : null,
          },
        });
        handleFunction = (value: EditModalModel) => {
          if (
            value != null &&
            (this.widget.name !== value.title ||
              this.widget.description !== value.description)
          ) {
            this.editAnnotation.emit({
              ...this.widget,
              name: value.title,
              description: value.description as string,
            });
          }
        };
        break;
      case 'AddToPlaylistModal':
        dialog = this.dialog.open(AddToPlaylistModalComponent, {
          data: {
            annotations: [this.widget],
            fromPlaylistId: this.fromPlaylistId,
          },
        });
        break;
      case 'RemoveModal':
        dialog = this.dialog.open(RemoveModalComponent, {
          data: {
            title: this.widget.name,
            type: INPUT_TYPE.ANNOTATION,
          },
        });
        handleFunction = (value: boolean) => {
          if (value) {
            this.deleteTag.emit(this.widget);
          }
        };
        break;
      case 'ShareModal':
        const shareable: ShareableEntityModel = {
          annotationsIds: [this.widget.id],
          type: ShareableEntityType.ANNOTATIONS,
          sharings: this.widget.sharings,
        };
        this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
        dialog = this.dialog.open(ShareModalComponent, {
          data: {
            entityType: ShareableEntityType.ANNOTATIONS,
          },
        });
        handleFunction = (value: string) => {
          if (value != null && this.widget.name !== value) {
            this.shareAnnotation.emit(this.widget);
          }
        };
        break;
    }
    if (dialog != null) {
      dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
    }
  }

  formatDuration(duration: number): string {
    return `${duration < 10 ? '0' : ''}${duration}`;
  }

  get duration(): string {
    const duration = this.widget.from + this.widget.to;
    return `${this.formatDuration(
      Math.floor(duration / SECONDS),
    )}:${this.formatDuration(Math.floor(duration % SECONDS))}`;
  }

  get matchName(): string {
    if (this.widget.teamHome && this.widget.teamAway) {
      const homeTeam = getRecordingTeamName(this.widget.teamHome);
      const awayTeam = getRecordingTeamName(this.widget.teamAway);

      return `${homeTeam} - ${awayTeam}`;
    }

    return '';
  }

  get matchDate(): string {
    if (this.widget.timeStamp) {
      return moment.unix(this.widget.timeStamp).format('DD - MM - YYYY');
    }
    return '';
  }

  get annotationTime(): string {
    if (this.widget.timeStamp) {
      return moment.unix(this.widget.timeStamp).format('HH:mm:ss');
    }
    return '';
  }

  get teamName(): string {
    return this.widget.team !== AnnotationTeam.NONE &&
      !!this.widget.teamHome &&
      !!this.widget.teamAway
      ? this.widget.team === AnnotationTeam.HOME
        ? this.widget.teamHome.clubName !== this.widget.teamHome.teamName
          ? `${this.widget.teamHome.clubName}/${this.widget.teamHome.teamName}`
          : this.widget.teamHome.clubName
        : this.widget.teamAway.clubName !== this.widget.teamAway.teamName
          ? `${this.widget.teamAway.clubName}/${this.widget.teamAway.teamName}`
          : this.widget.teamAway.clubName
      : '';
  }

  get downloadButtonLabel(): string {
    return this.widget.status === RenderStatus.RENDERING
      ? 'basic.renderingAnnotation'
      : 'basic.downloadAnnotation';
  }

  get downloadButtonIcon(): string {
    switch (this.widget.status) {
      case RenderStatus.RENDERING:
        return 'cmv-loading';
      case RenderStatus.FAILED:
        return 'cmv-retry';
      default:
        return 'cmv-download';
    }
  }

  get widgetNameClasses(): string {
    return this.showRecordingName
      ? 'video-detail cmv-paragraph'
      : 'cmv-h5 video-title';
  }
}
