<div class="created-tags-tools">
  @if (mBreakPoint$ | async) {
    <div class="created-tags-header--buttons">
      <button
        mat-icon-button
        (click)="toggleMultiselect()"
        class="multiselect-icon"
      >
        <mat-icon
          class="button-icon cmv-dim-gray-icon"
          [svgIcon]="multiselectIcon$ | async"
        ></mat-icon>
      </button>
      @if (showFilter$ | async) {
        <button (click)="toggleFilter()" mat-icon-button class="filter-icon">
          <mat-icon
            class="button-icon cmv-dim-gray-icon"
            [svgIcon]="filterIcon$ | async"
          ></mat-icon>
        </button>
      }
    </div>
  }

  @if (isFilterEnabled$ | async) {
    <cmv-created-tags-filter />
  }
</div>

@if (showEmptyList && annotations.length === 0) {
  <div class="empty-taglist">
    <div class="empty-taglist-image">
      <img src="assets/images/empty_state.svg" />
    </div>
    <div class="empty-taglist-title cmv-h2">
      {{ 'components.liveMatchTagging.emptyTaglistTitle' | translate }}
    </div>
    <div class="empty-taglist-subtitle cmv-body">
      {{ 'components.liveMatchTagging.emptyTaglistSubtitle' | translate }}
    </div>
  </div>
}

@for (annotation of annotations; track annotation.id) {
  <div class="cmv-video-widget-container" [@tagsAnimation]="'created'">
    <cmv-video-widget
      [id]="annotation.id"
      [widgetData]="annotation"
      [trimmingDisabled]="trimmingDisabled$ | async"
      (selectedWidgetIdChange)="selectedWidgetIdChange.emit($event)"
      (editAnnotation)="editAnnotation.emit($event)"
      (trimVideo)="trimVideo.emit($event)"
      (deleteTag)="deleteTag.emit($event)"
      [fromPlaylistId]="fromPlaylistId"
      [hideDownload]="hideDownloadTag"
      [trimmingDisabled]="trimmingDisabled"
      [showRecordingName]="showRecordingName"
    ></cmv-video-widget>
  </div>
}
