<div class="header-wrapper">
  <cmv-header
    [title]="'components.recordingList.title' | translate"
    [subtitle]="'components.recordingList.subtitle' | translate"
    [searchButtonEnabled]="true"
    [searchBarVisible]="searchBarVisible$ | async"
    [actionButtonTooltip]="'tooltips.createRecording' | translate"
    [actionButtonEnabled]="hasAnyRecorder$ | async"
    (actionButtonClicked)="createRecording()"
  ></cmv-header>
</div>
<ng-container *ngIf="recordingsLoaded$ | async; else loading">
  <div class="match-list-group-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      preserveContent="true"
      [selectedIndex]="matchesTabIndex$ | async"
      (focusChange)="focusChange($event)"
      (selectedTabChange)="tabIndexChange($event)"
    >
      <mat-tab class="mat-tab-tagging">
        <ng-template mat-tab-label>
          <div
            class="mat-tab-label-wrapper"
            (click)="labelClick(MatchList.RECORDED)"
          >
            <div class="cmv-h4">
              {{ 'components.recordingList.current.tabTitle' | translate }}
            </div>
          </div>
        </ng-template>

        <cmv-match-list
          [index]="MatchList.RECORDED"
          [matchDays]="currentMatchDay$ | async"
          [showUpgrade]="premiumMatchesExist$ | async"
          [showMoreButton]="true"
          (destroy)="onMatchListDestroy($event)"
          #recordedMatchList
        ></cmv-match-list>
      </mat-tab>
      <mat-tab class="mat-tab-tagging">
        <ng-template mat-tab-label>
          <div
            class="mat-tab-label-wrapper"
            (click)="labelClick(MatchList.UPCOMING)"
          >
            <div class="cmv-h4">
              {{ 'components.recordingList.upcoming.tabTitle' | translate }}
            </div>
          </div>
        </ng-template>
        <div class="tab-content">
          <cmv-match-list
            [index]="MatchList.UPCOMING"
            [matchDays]="upcomingMatchDay$ | async"
            [showUpgrade]="premiumMatchesExist$ | async"
            (destroy)="onMatchListDestroy($event)"
            #upcomingMatchList
          ></cmv-match-list>
        </div>
      </mat-tab>
      <mat-tab label="" class="empty-label"></mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
<ng-template #loading>
  <cmv-loading-state></cmv-loading-state>
</ng-template>
