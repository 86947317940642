@if (!searchBarVisible) {
  <div class="header-content-wrapper">
    <div class="header">
      <div class="header-text-container">
        <div class="cmv-h1 title">{{ title }}</div>
      </div>
      <div class="search-button-container">
        @if (searchButtonEnabled) {
          <div
            class="search-button"
            (click)="activateSearchBar()"
            [matTooltip]="'tooltips.search' | translate"
          >
            <mat-icon
              class="cmv-white-icon search-icon"
              svgIcon="cmv-search-left"
            ></mat-icon>
          </div>
        }

        @if (actionButtonEnabled) {
          <div
            class="search-button"
            (click)="emitActionButton()"
            [matTooltip]="actionButtonTooltip"
          >
            <mat-icon
              class="cmv-white-icon plus-icon"
              [svgIcon]="actionButtonIcon"
            ></mat-icon>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (searchButtonEnabled && searchBarVisible) {
  <cmv-search></cmv-search>
}
