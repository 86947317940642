import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Annotation, AnnotationList, RenderStatus } from 'src/api/models';
import { AppState } from '../../models/app.state';
import {
  ShareableEntityModel,
  ShareableEntityType,
} from '../../models/sharing.model';
import { getSharingTeamsRequestAction } from '../../store/actions/sharing.actions';
import { ShareModalComponent } from '../modal/share-modal/share-modal.component';
import { downloadAnnotationsListRequestAction } from '../../store/actions/annotations-lists.actions';
import {
  $currentRecordingHasVideo,
  $isMultiselectEnabled,
  $isSelectionEmpty,
  $selectedAnnotations,
  $selectionEmptyOrContainsSharedAnnotations,
} from '../../store/selectors/current-selections.selectors';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import {
  deleteAnnotationsRequestAction,
  downloadAnnotationRequestAction,
} from '../../store/actions/annotation.actions';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { take } from 'rxjs';
import { AddToPlaylistModalComponent } from '../modal/add-to-playlist-modal/add-to-playlist-modal.component';

@Component({
  selector: 'cmv-created-tags-more',
  templateUrl: './created-tags-more.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
    TranslateModule,
  ],
})
export class CreatedTagsMoreComponent {
  @Input() shareableList: AnnotationList;
  @Input() annotationsEmpty: Boolean = true;
  @Input() isDownloadPrepared: Boolean = false;

  readonly currentRecordingHasVideo$ = this.store.pipe(
    select($currentRecordingHasVideo),
  );

  readonly isMultiselectEnabled$ = this.store.pipe(
    select($isMultiselectEnabled),
  );

  readonly isSelectionEmpty$ = this.store.pipe(select($isSelectionEmpty));
  readonly selectionEmptyOrContainsSharedAnnotations$ = this.store.pipe(
    select($selectionEmptyOrContainsSharedAnnotations),
  );

  readonly selectedAnnotations$ = this.store.pipe(select($selectedAnnotations));

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  shareAnnotationList(annotationList: AnnotationList): void {
    const shareable: ShareableEntityModel = {
      annotationListId: annotationList.id,
      sharings: annotationList.sharings,
      type: ShareableEntityType.LIST,
    };
    this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
    this.dialog.open(ShareModalComponent, {
      data: {
        entityType: ShareableEntityType.LIST,
      },
    });
  }

  downloadAnnotationList(annotationList: AnnotationList): void {
    if (annotationList.status === RenderStatus.DONE && annotationList.url) {
      window.open(annotationList.url, '_blank');
    } else if (annotationList.status !== RenderStatus.RENDERING) {
      this.store.dispatch(
        downloadAnnotationsListRequestAction({ id: annotationList.id }),
      );
    }
  }

  addToPlaylist(annotations: Annotation[]): void {
    this.dialog.open(AddToPlaylistModalComponent, {
      data: {
        annotations,
      },
    });
  }

  shareAnnotations(annotations: Annotation[]): void {
    const shareable: ShareableEntityModel = {
      annotationsIds: annotations.map(annotation => annotation.id),
      sharings: annotations[0].sharings?.filter(
        sharing =>
          annotations.every(
            annotation =>
              annotation.sharings?.findIndex(
                s => s.teamId === sharing.teamId && s.userId === sharing.userId,
              ) > -1,
          ) ?? [],
      ),
      type: ShareableEntityType.ANNOTATIONS,
    };

    this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
    this.dialog.open(ShareModalComponent, {
      data: {
        entityType: ShareableEntityType.ANNOTATIONS,
        multiple: annotations.length > 1,
      },
    });
  }

  downloadAnnotations(annotations: Annotation[]): void {
    annotations.forEach(annotation => {
      if (annotation.status === RenderStatus.DONE && annotation.url) {
        window.open(annotation.url, '_blank');
      } else if (annotation.status !== RenderStatus.RENDERING) {
        this.store.dispatch(
          downloadAnnotationRequestAction({ id: annotation.id }),
        );
      }
    });
  }

  deleteAnnotations(annotations: Annotation[]): void {
    const dialog = this.dialog.open(RemoveModalComponent, {
      data: {
        title: annotations.map(annotation => annotation.name).join(', '),
        type:
          annotations.length > 1
            ? INPUT_TYPE.ANNOTATIONS
            : INPUT_TYPE.ANNOTATION,
      },
    });
    const handleFunction = (value: boolean) => {
      if (value) {
        this.store.dispatch(deleteAnnotationsRequestAction({ annotations }));
      }
    };

    dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
  }

  get downloadButtonLabel(): string {
    return this.shareableList?.status === RenderStatus.RENDERING
      ? 'basic.renderingAnnotations'
      : 'basic.downloadAnnotations';
  }

  get downloadButtonIcon(): string {
    switch (this.shareableList?.status) {
      case RenderStatus.RENDERING:
        return 'cmv-loading';
      case RenderStatus.FAILED:
        return 'cmv-retry';
      default:
        return 'cmv-download';
    }
  }
}
