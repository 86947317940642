import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Annotation } from 'src/api/models';
import { TAGS_ANIMATION } from 'src/app/app.utils';
import { AppState } from '../../models/app.state';
import {
  $isAnnotationFilterEnabled,
  $isMultiselectEnabled,
  $showFilter,
  $trimmingDisabled,
} from '../../store/selectors/current-selections.selectors';
import { AsyncPipe } from '@angular/common';
import { VideoWidgetComponent } from '../video-widget/video-widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { CreatedTagsFilterComponent } from './created-tags-filter/created-tags-filter.component';
import { $mBreakPoint } from '../../store/selectors/responsivity.selectors';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { map } from 'rxjs';
import {
  toggleAnnotationFilterAction,
  toggleMultiselectAction,
} from '../../store/actions/current-selections.actions';

@Component({
  selector: 'cmv-created-tags',
  templateUrl: './created-tags.component.html',
  styleUrls: ['./created-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: TAGS_ANIMATION,
  imports: [
    CreatedTagsFilterComponent,
    VideoWidgetComponent,
    AsyncPipe,
    TranslateModule,
    MatIcon,
    MatIconButton,
  ],
})
export class CreatedTagsComponent {
  @Input() showEmptyList: boolean = false;
  @Input() annotations: Annotation[] = [];
  @Input() currentAnnotation: Annotation | null = null;
  @Input() fromPlaylistId: string | undefined;
  @Input() hideDownloadTag: boolean = false;
  @Input() trimmingDisabled: boolean = false;
  @Input() showRecordingName: boolean = false;

  @Output() deleteTag = new EventEmitter<Annotation>();
  @Output() selectedWidgetIdChange = new EventEmitter<{
    id: string;
    recordingId: string;
    annotation: Annotation;
  }>();
  @Output() trimVideo = new EventEmitter<{ id: string; recordingId: string }>();
  @Output() editAnnotation = new EventEmitter<Annotation>();

  readonly mBreakPoint$ = this.store.pipe(select($mBreakPoint));
  readonly trimmingDisabled$ = this.store.pipe(select($trimmingDisabled));

  readonly isMultiselectEnabled$ = this.store.select($isMultiselectEnabled);
  readonly multiselectIcon$ = this.isMultiselectEnabled$.pipe(
    map(isEnabled =>
      isEnabled ? 'cmv-multiselect-cancel' : 'cmv-multiselect',
    ),
  );

  readonly showFilter$ = this.store.select($showFilter);
  readonly isFilterEnabled$ = this.store.pipe(
    select($isAnnotationFilterEnabled),
  );
  readonly filterIcon$ = this.isFilterEnabled$.pipe(
    map(isFilterEnabled =>
      isFilterEnabled ? 'cmv-filter-cancel' : 'cmv-filter',
    ),
  );

  constructor(private readonly store: Store<AppState>) {}

  toggleMultiselect() {
    this.store.dispatch(toggleMultiselectAction());
  }

  toggleFilter() {
    this.store.dispatch(toggleAnnotationFilterAction());
  }
}
