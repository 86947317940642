import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppState } from '../../../models/app.state';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { $sharingTeamsStateMappedExpand } from '../../../store/selectors/sharing.selectors';
import { ShareableEntityType } from '../../../models/sharing.model';
import { MatIcon } from '@angular/material/icon';
import { TreeComponent } from '../../tree/tree.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

const BASIC_PATH = 'components.modals.shareModal';

const TITLE_ANNOTATION = 'titleAnnotation';
const TITLE_ANNOTATIONS = 'titleAnnotations';

const SUBTITLE_ANNOTATION = 'subtitleAnnotation';
const SUBTITLE_ANNOTATIONS = 'subtitleAnnotations';

const TITLE_LIST = 'titleList';
const SUBTITLE_LIST = 'subtitleList';

@Component({
  selector: 'cmv-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, TreeComponent, AsyncPipe, TranslateModule],
})
export class ShareModalComponent implements OnDestroy, AfterViewInit {
  readonly unsubscribe$ = new Subject<void>();

  readonly sharingData$ = this.store.pipe(
    select($sharingTeamsStateMappedExpand),
  );

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      entityType: ShareableEntityType;
      multiple?: boolean;
    },
    private readonly store: Store<AppState>,
  ) {}

  get titleKey(): string {
    if (this.data.entityType === ShareableEntityType.LIST) {
      return `${BASIC_PATH}.${TITLE_LIST}`;
    } else if (this.data.multiple) {
      return `${BASIC_PATH}.${TITLE_ANNOTATIONS}`;
    } else {
      return `${BASIC_PATH}.${TITLE_ANNOTATION}`;
    }
  }

  get subtitleKey(): string {
    if (this.data.entityType === ShareableEntityType.LIST) {
      return `${BASIC_PATH}.${SUBTITLE_LIST}`;
    } else if (this.data.multiple) {
      return `${BASIC_PATH}.${SUBTITLE_ANNOTATIONS}`;
    } else {
      return `${BASIC_PATH}.${SUBTITLE_ANNOTATION}`;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(): void {
    this.dialog.closeAll();
  }
}
