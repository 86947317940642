import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fromEvent, of, EMPTY, from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../../api';
import {
  AnnotationsIdsBody,
  Playlist,
  PlaylistStatus,
} from '../../../../api/models';
import { reportToGA } from '../../../app.utils';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  addToPlaylistFailureAction,
  addToPlaylistRequestAction,
  addToPlaylistSuccessAction,
  createdPlaylistFailureAction,
  createdPlaylistRequestAction,
  createdPlaylistSuccessAction,
  deletedPlaylistRequestAction,
  deletePlaylistFailureAction,
  deletePlaylistRequestAction,
  deletePlaylistSuccessAction,
  editAnnotationNameInPlaylistRequestAction,
  editAnnotationTrimmingInPlaylistAction,
  getPlaylistListFailureAction,
  getPlaylistListRequestAction,
  getPlaylistListSuccessAction,
  postPlaylistFailureAction,
  postPlaylistRequestAction,
  postPlaylistSuccessAction,
  putPlaylistFailureAction,
  putPlaylistRequestAction,
  putPlaylistSuccessAction,
  refreshPlaylistFailureAction,
  refreshPlaylistRequestAction,
  refreshPlaylistsByRecordingIdAction,
  refreshPlaylistsByRecordingsIdsAction,
  refreshPlaylistSuccessAction,
  removeFromPlaylistFailureAction,
  removeFromPlaylistRequestAction,
  removeFromPlaylistSuccessAction,
  renderPlaylistFailureAction,
  renderPlaylistRequestAction,
  renderPlaylistSuccessAction,
} from '../actions/playlist-list.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import { AppState } from '../../models/app.state';
import { $playlistEntities } from '../selectors/playlist-list.selectors';
import {
  GoogleAnalyticsEvent,
  QueryParamNames,
  RoutePath,
  WSStatus,
} from '../../../app.constants';
import {
  $currentPlaylist,
  $currentPlaylistAnnotation,
  $userDownloadingPlaylistIds,
} from '../selectors/current-selections.selectors';
import {
  $subSectionRoute,
  $sectionRoute,
  $pathSegments,
} from '../selectors/route.selectors';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { removeDownloadPlaylistId } from '../actions/current-selections.actions';

@Injectable()
export class PlaylistListEffects {
  getPlaylistListAfterFocus$ = createEffect(() =>
    fromEvent(document, 'visibilitychange').pipe(
      filter(() => document.visibilityState === 'visible'),
      switchMap(() =>
        this.store.pipe(
          select($pathSegments),
          filter(
            ([, section, subsection]) =>
              section === RoutePath.Playlists && subsection === undefined,
          ),
          map(() => getPlaylistListRequestAction()),
        ),
      ),
    ),
  );

  getPlaylistList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPlaylistListRequestAction),
      withLatestFrom(
        this.store.select($sectionRoute),
        this.store.select($subSectionRoute),
        this.store.select($currentPlaylist),
        this.store.select($currentPlaylistAnnotation),
      ),
      switchMap(
        ([{}, section, subsection, currentPlaylist, currentAnnotation]) =>
          this.apiService
            .call(() => this.apiClient.getPlaylist())
            .pipe(
              map(playlists => {
                const playlist = playlists.find(
                  p => p.id === currentPlaylist?.id,
                );
                const annotations = (playlist?.annotations ?? []).filter(
                  a => a.show,
                );

                if (section === RoutePath.Playlists && playlist) {
                  if (
                    subsection === RoutePath.Clip &&
                    annotations.length === 0
                  ) {
                    this.router.navigate(
                      [RoutePath.Platform, RoutePath.Playlists],
                      {
                        replaceUrl: true,
                      },
                    );
                  } else if (
                    subsection === RoutePath.Clip &&
                    !annotations.find(a => a.id === currentAnnotation?.id)
                  ) {
                    this.router.navigate(
                      [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
                      {
                        replaceUrl: true,
                        queryParams: {
                          [QueryParamNames.PlaylistId]: playlist.id,
                          [QueryParamNames.AnnotationId]: annotations[0].id,
                        },
                      },
                    );
                  }
                }

                return getPlaylistListSuccessAction({ playlists });
              }),
              catchError(error => {
                const parsedError = {
                  type: error.status.toString(),
                  message: error.error.msg,
                };

                return of(
                  getPlaylistListFailureAction({
                    errors: [parsedError],
                  }),
                  handleErrorResponseAction({
                    errorType: parsedError.type,
                  }),
                );
              }),
            ),
      ),
    ),
  );

  postPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postPlaylistRequestAction),
      switchMap(({ playlist }) =>
        this.apiService
          .call(() =>
            this.apiClient.postPlaylist({ body: playlist as Playlist }),
          )
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.PLAYLIST_CREATE);
            }),
            switchMap(response =>
              of(
                postPlaylistSuccessAction({ playlist: response }),
                showSnackbarAction({
                  entryBegin: response.name,
                  infoMessage: 'success.playlist.create',
                }),
              ),
            ),
            catchError(error =>
              of(
                postPlaylistFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  entryBegin: playlist.name,
                  infoMessage: 'error.playlist.create',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deletePlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePlaylistRequestAction),
      switchMap(({ playlist }) =>
        this.apiService
          .call(() => this.apiClient.deletePlaylistId({ id: playlist.id }))
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.PLAYLIST_DELETE);
            }),
            switchMap(() =>
              of(
                deletePlaylistSuccessAction({ id: playlist.id }),
                showSnackbarAction({
                  entryBegin: playlist.name,
                  infoMessage: 'success.playlist.delete',
                  icon: 'delete',
                }),
              ),
            ),
            catchError(error =>
              of(
                deletePlaylistFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  entryBegin: playlist.name,
                  infoMessage: 'error.playlist.delete',
                  icon: 'delete',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  putPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putPlaylistRequestAction),
      switchMap(({ playlist }) =>
        this.apiService
          .call(() => this.apiClient.putPlaylist({ body: playlist }))
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.PLAYLIST_EDIT);
            }),
            switchMap(response =>
              of(
                putPlaylistSuccessAction({ playlist: response }),
                showSnackbarAction({
                  entryBegin: response.name,
                  infoMessage: 'success.playlist.edit',
                  icon: 'edit',
                }),
              ),
            ),
            catchError(error =>
              of(
                putPlaylistFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  entryBegin: playlist.name,
                  infoMessage: 'error.playlist.edit',
                  icon: 'edit',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  editAnnotationTrimmingInPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAnnotationTrimmingInPlaylistAction),
      switchMap(payload =>
        this.apiService
          .call(() => this.apiClient.patchPlaylistId(payload))
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.TRIM_TAG, {
                playlist: true,
              });
            }),
            switchMap(playlist =>
              of(
                putPlaylistSuccessAction({ playlist }),
                showSnackbarAction({
                  entryBegin: payload.body.name,
                  infoMessage: 'success.videoDetail.trim',
                  icon: 'trim',
                }),
              ),
            ),
            catchError(error =>
              of(
                putPlaylistFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  entryBegin: payload.body.name,
                  infoMessage: 'error.videoDetail.trim',
                  icon: 'trim',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  editAnnotationNameInPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAnnotationNameInPlaylistRequestAction),
      switchMap(({ playlistId, annotation: { id, name, description } }) =>
        this.apiService
          .call(() =>
            this.apiClient.patchPlaylistId({
              id: playlistId,
              body: { id, name, description },
            }),
          )
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.EDIT_TAG, {
                playlist: true,
              });
            }),
            switchMap(playlist =>
              of(
                putPlaylistSuccessAction({ playlist }),
                showSnackbarAction({
                  entryBegin: name,
                  infoMessage: 'success.videoDetail.edit',
                  icon: 'edit',
                }),
              ),
            ),
            catchError(error =>
              of(
                putPlaylistFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  entryBegin: name,
                  infoMessage: 'error.videoDetail.edit',
                  icon: 'edit',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  removeFromPlaylist = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFromPlaylistRequestAction),
      withLatestFrom(
        this.store.select($sectionRoute),
        this.store.select($subSectionRoute),
        this.store.select($currentPlaylist),
        this.store.select($currentPlaylistAnnotation),
      ),
      switchMap(
        ([
          payload,
          section,
          subsection,
          currentPlaylist,
          currentPlaylistAnnotation,
        ]) =>
          this.apiService
            .call(() =>
              this.apiClient.deleteAnnotationPlaylistId({
                body: {
                  annotationsIds: payload.annotationsIds,
                },
                playlistId: payload.playlistId,
              }),
            )
            .pipe(
              tap(() => {
                reportToGA(GoogleAnalyticsEvent.REMOVE_FROM_PLAYLIST);
              }),
              switchMap(playlist => {
                if (
                  section === RoutePath.Playlists &&
                  subsection === RoutePath.Clip &&
                  currentPlaylist?.id === playlist.id
                ) {
                  const annotations = (playlist.annotations ?? []).filter(
                    a => a.show,
                  );

                  if (annotations.length === 0) {
                    this.router.navigate(
                      [RoutePath.Platform, RoutePath.Playlists],
                      {
                        replaceUrl: true,
                      },
                    );
                  } else if (
                    !annotations.find(
                      a => a.id === currentPlaylistAnnotation?.id,
                    )
                  ) {
                    this.router.navigate(
                      [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
                      {
                        replaceUrl: true,
                        queryParams: {
                          [QueryParamNames.PlaylistId]: playlist.id,
                          [QueryParamNames.AnnotationId]: annotations[0].id,
                        },
                      },
                    );
                  }
                }

                return of(
                  removeFromPlaylistSuccessAction({ playlist }),
                  showSnackbarAction({
                    entryBegin: payload.annotationName,
                    entryEnd: payload.playlistName,
                    infoMessage: 'success.playlist.remove',
                    icon: 'add-playlist',
                  }),
                );
              }),
              catchError(error =>
                of(
                  removeFromPlaylistFailureAction({
                    errors: [
                      {
                        type: error.status.toString(),
                        message: error.error.msg,
                      },
                    ],
                  }),
                  showSnackbarAction({
                    entryBegin: payload.annotationName,
                    entryEnd: payload.playlistName,
                    infoMessage: 'error.playlist.remove',
                    icon: 'add-playlist',
                  }),
                ),
              ),
            ),
      ),
    ),
  );

  addToPlaylist = createEffect(() =>
    this.actions$.pipe(
      ofType(addToPlaylistRequestAction),
      switchMap(payload =>
        this.apiService
          .call(() => {
            const args: {
              playlistId: string;
              fromPlaylistId?: string | undefined;
              body: AnnotationsIdsBody;
            } = {
              playlistId: payload.playlistId,
              body: {
                annotationsIds: payload.annotationsIds,
              },
            };

            if (payload.fromPlaylistId) {
              args.fromPlaylistId = payload.fromPlaylistId;
            }

            return this.apiClient.postAnnotationPlaylistId({
              playlistId: payload.playlistId,
              body: {
                annotationsIds: payload.annotationsIds,
              },
            });
          })
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.ADD_TO_PLAYLIST);
            }),
            switchMap(playlist =>
              of(
                addToPlaylistSuccessAction({ playlist }),
                showSnackbarAction({
                  entryBegin: payload.annotationName,
                  entryEnd: payload.playlistName,
                  infoMessage: 'success.playlist.add',
                  icon: 'add-playlist',
                }),
              ),
            ),
            catchError(error =>
              of(
                addToPlaylistFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  entryBegin: payload.annotationName,
                  entryEnd: payload.playlistName,
                  infoMessage: 'error.playlist.add',
                  icon: 'add-playlist',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  renderPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(renderPlaylistRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getPlaylistDownloadId({ id }))
          .pipe(
            switchMap(playlist =>
              of(renderPlaylistSuccessAction({ playlist })),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.PLAYLIST_DOWNLOAD);
            }),
            catchError((err: HttpErrorResponse) =>
              of(
                renderPlaylistFailureAction(),
                showSnackbarAction({
                  infoMessage: `error.playlistDownload.${err.status}`,
                  messageParameters: { ...err.error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  refreshPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshPlaylistRequestAction),
      withLatestFrom(
        this.store.select($sectionRoute),
        this.store.select($subSectionRoute),
        this.store.select($currentPlaylist),
        this.store.select($currentPlaylistAnnotation),
        this.store.select($userDownloadingPlaylistIds),
      ),
      switchMap(
        ([
          { id, showSnackbar, status },
          route,
          subsection,
          currentPlaylist,
          currentPlaylistAnnotation,
          userDownloadingPlaylistIds,
        ]) =>
          this.apiService
            .call(() => this.apiClient.getPlaylistId({ id }))
            .pipe(
              tap(playlist => {
                if (
                  route === RoutePath.Playlists ||
                  playlist.id === currentPlaylist?.id
                ) {
                  this.handlePlaylistRedirect(
                    playlist,
                    subsection,
                    currentPlaylistAnnotation?.id,
                  );
                }

                if (
                  status === WSStatus.DONE &&
                  playlist.url &&
                  userDownloadingPlaylistIds.includes(playlist.id)
                ) {
                  window.open(playlist.url, '_blank');
                }
              }),
              switchMap(playlist =>
                from([
                  refreshPlaylistSuccessAction({ playlist }),
                  ...(showSnackbar
                    ? [
                        showSnackbarAction({
                          infoMessage: getRenderMessage(
                            playlist.status || PlaylistStatus.DEFAULT,
                          ),
                          icon: 'add-playlist',
                        }),
                      ]
                    : []),
                  ...(status === WSStatus.DONE || status === WSStatus.FAILED
                    ? [removeDownloadPlaylistId({ playlistId: playlist.id })]
                    : []),
                ]),
              ),
              catchError(error =>
                of(
                  refreshPlaylistFailureAction({
                    errors: [
                      {
                        type: error.status.toString(),
                        message: error.error.msg,
                      },
                    ],
                  }),
                  showSnackbarAction({
                    infoMessage: 'error.playlist.renderEnd',
                    icon: 'add-playlist',
                  }),
                ),
              ),
            ),
      ),
    ),
  );

  deletedPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletedPlaylistRequestAction),
      withLatestFrom(
        this.store.select($sectionRoute),
        this.store.select($subSectionRoute),
        this.store.select($currentPlaylist),
      ),
      switchMap(([{ id }, section, subsection, currentPlaylist]) => {
        if (
          section === RoutePath.Playlists &&
          subsection === RoutePath.Clip &&
          currentPlaylist?.id === id
        ) {
          this.router.navigate([RoutePath.Platform, RoutePath.Playlists], {
            replaceUrl: true,
          });
        }

        return of(deletePlaylistSuccessAction({ id }));
      }),
    ),
  );

  createdPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createdPlaylistRequestAction),
      withLatestFrom(this.store.select($playlistEntities)),
      switchMap(([{ id }, playlists]) =>
        !playlists.find(p => p.id === id)
          ? this.apiService
              .call(() => this.apiClient.getPlaylistId({ id }))
              .pipe(
                switchMap(playlist =>
                  of(createdPlaylistSuccessAction({ playlist })),
                ),
                catchError(error => of(createdPlaylistFailureAction(error))),
              )
          : EMPTY,
      ),
    ),
  );

  refreshPlaylistByRecordingId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshPlaylistsByRecordingIdAction),
      withLatestFrom(this.store.select($playlistEntities)),
      switchMap(([{ id }, playlists]) =>
        playlists.find(p => p.recordingsIds?.find(i => i === id))
          ? of(getPlaylistListRequestAction())
          : EMPTY,
      ),
    ),
  );

  refreshPlaylistByRecordingsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshPlaylistsByRecordingsIdsAction),
      withLatestFrom(this.store.select($playlistEntities)),
      switchMap(([{ ids }, playlists]) =>
        playlists.find(p => p.recordingsIds?.find(i => ids.includes(i)))
          ? of(getPlaylistListRequestAction())
          : EMPTY,
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly apiService: ApiService,
  ) {}

  handlePlaylistRedirect = (
    playlist: Playlist,
    subsection: string | null,
    currentPlaylistAnnotationId: string | undefined,
  ) => {
    const annotations = (playlist.annotations ?? []).filter(a => a.show);

    if (subsection === RoutePath.Clip && annotations.length === 0) {
      this.router.navigate([RoutePath.Platform, RoutePath.Playlists], {
        replaceUrl: true,
      });
    } else if (
      subsection === RoutePath.Clip &&
      !annotations.find(a => a.id === currentPlaylistAnnotationId)
    ) {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
        {
          replaceUrl: true,
          queryParams: {
            [QueryParamNames.PlaylistId]: playlist.id,
            [QueryParamNames.AnnotationId]: annotations[0].id,
          },
        },
      );
    }
  };
}

const getRenderMessage = (status: PlaylistStatus): string => {
  const success = 'success.playlist.';
  const failure = 'error.playlist.';
  switch (status) {
    case PlaylistStatus.RENDERING:
      return `${success}renderStart`;
    case PlaylistStatus.DONE:
      return `${success}renderEnd`;
    case PlaylistStatus.FAILED:
      return `${failure}renderEnd`;
    default:
      return `${failure}renderDefault`;
  }
};
