<div
  class="created-tags-more"
  *ngIf="selectedAnnotations$ | async as selectedAnnotations"
>
  <div class="buttons-wrapper">
    <button
      class="cmv-more-button"
      mat-icon-button
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="detailMenu"
    >
      <img src="assets/icons/more_icon_ver.svg" />
    </button>
  </div>
  <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
    <div (keydown)="$event.stopPropagation()">
      @if (isMultiselectEnabled$ | async) {
        <!-- Add to playlist -->
        <button
          mat-menu-item
          class="cmv-menu-item"
          (click)="addToPlaylist(selectedAnnotations)"
          [disabled]="isSelectionEmpty$ | async"
        >
          <span class="cmv-h4">
            {{ 'basic.addToPlaylist' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              svgIcon="cmv-add-playlist"
            ></mat-icon>
          </span>
        </button>
        <!-- Share tags -->
        <button
          mat-menu-item
          class="cmv-menu-item"
          (click)="shareAnnotations(selectedAnnotations)"
          [disabled]="selectionEmptyOrContainsSharedAnnotations$ | async"
        >
          <span class="cmv-h4">
            {{ 'basic.shareAnnotations' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-white-icon"
              svgIcon="cmv-share"
            ></mat-icon>
          </span>
        </button>
        <!-- Download tags -->
        @if (currentRecordingHasVideo$ | async) {
          <button
            mat-menu-item
            class="cmv-menu-item"
            [disabled]="isSelectionEmpty$ | async"
            (click)="downloadAnnotations(selectedAnnotations)"
          >
            <span class="cmv-h4">
              {{ downloadButtonLabel | translate }}
            </span>
            <span class="menu-icon--wrapper">
              <mat-icon
                class="modal-icon cmv-white-icon"
                [svgIcon]="downloadButtonIcon"
              ></mat-icon>
            </span>
          </button>
        }
        <!-- Remove tags -->
        <button
          mat-menu-item
          class="cmv-menu-item"
          (click)="deleteAnnotations(selectedAnnotations)"
          [disabled]="selectionEmptyOrContainsSharedAnnotations$ | async"
        >
          <span class="cmv-h4">
            {{ 'basic.removeTags' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              svgIcon="cmv-delete"
            ></mat-icon>
          </span>
        </button>
      } @else {
        <button
          class="cmv-menu-item"
          (click)="shareAnnotationList(shareableList)"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ 'basic.shareList' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              svgIcon="cmv-share"
            ></mat-icon>
          </span>
        </button>
        <button
          *ngIf="!annotationsEmpty && (currentRecordingHasVideo$ | async)"
          class="cmv-menu-item"
          (click)="downloadAnnotationList(shareableList)"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ downloadButtonLabel | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              [svgIcon]="downloadButtonIcon"
            ></mat-icon>
          </span>
        </button>
      }
    </div>
  </mat-menu>
</div>
