import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import {
  animate,
  AnimationEvent, // eslint-disable-line no-redeclare
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AppState } from '../../models/app.state';
import { select, Store } from '@ngrx/store';
import { animationFrameScheduler, interval, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  $snackbarEntryBegin,
  $snackbarEntryEnd,
  $snackbarIcon,
  $snackbarMessage,
  $snackbarMessageParameters,
  $snackbarUndoTagId,
  $snackbarWillHideAt,
} from '../../store/selectors/snackbar.selectors';
import { $annotation } from '../../store/selectors/annotation.selectors';
import { MatDialog } from '@angular/material/dialog';
import { deleteAnnotationsRequestAction } from '../../store/actions/annotation.actions';
import { $isMenuCollapsed } from '../../store/selectors/ui-flags.selectors';
import { NgIf, NgStyle, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

const ANIMATION_FAST = 300;
const SNACKBAR_HEIGHT = '-65px';

@Component({
  selector: 'cmv-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('snackBarState', [
      state(
        'hidden',
        style({
          display: 'none',
          bottom: SNACKBAR_HEIGHT,
        }),
      ),
      state(
        'shown',
        style({
          display: 'block',
          bottom: '0',
        }),
      ),
      transition('hidden <=> shown', animate(ANIMATION_FAST)),
    ]),
  ],
  imports: [NgIf, MatIcon, NgStyle, AsyncPipe, TranslateModule],
})
export class SnackbarComponent implements OnDestroy {
  snackbarState: 'shown' | 'hidden' = 'hidden';
  progressWidth$: Observable<string> | null = null;
  isMenuCollapsed$ = this.store.pipe(select($isMenuCollapsed));

  snackbarIcon$ = this.store.pipe(select($snackbarIcon));
  snackbarMessage$ = this.store.pipe(select($snackbarMessage));
  snackbarMessageParameters$ = this.store.pipe(
    select($snackbarMessageParameters),
  );

  snackbarEntryBegin$ = this.store.pipe(select($snackbarEntryBegin));
  snackbarEntryEnd$ = this.store.pipe(select($snackbarEntryEnd));

  snackbarUndoTagId$ = this.store.pipe(select($snackbarUndoTagId));

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public readonly dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store<AppState>,
  ) {
    this.store
      .pipe(select($snackbarWillHideAt), takeUntil(this.unsubscribe$))
      .subscribe(hideSnackbarAt => {
        if (hideSnackbarAt != null) {
          this.showSnackbar(hideSnackbarAt);
        } else {
          this.hideSnackbar();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showSnackbar(hideSnackbarAt: number): void {
    this.snackbarState = 'shown';
    this.cd.markForCheck();

    const progressStart = Date.now() + ANIMATION_FAST;
    const progressTime = hideSnackbarAt - progressStart;
    this.progressWidth$ = interval(0, animationFrameScheduler).pipe(
      map(() => 1 - (Date.now() - progressStart) / progressTime),
      map(ratio => `${ratio * 100}%`),
    );
  }

  hideSnackbar(): void {
    this.snackbarState = 'hidden';
    this.progressWidth$ = null;
  }

  onClickUndo(): void {
    this.snackbarUndoTagId$
      .pipe(
        switchMap((id: string) => this.store.pipe(select($annotation(id)))),
        filter(annotation => annotation != null),
        take(1),
      )
      .subscribe(annotation => {
        this.store.dispatch(
          deleteAnnotationsRequestAction({ annotations: [annotation] }),
        );
      });
  }

  snackbarShowDone(event: AnimationEvent): void {
    if (event.fromState === 'shown' && event.toState === 'hidden') {
      this.progressWidth$ = null;
    }
  }
}
